import { FC, useCallback, useState } from "react";
import { gql, useQuery } from '@apollo/client';
import { CaseProviderAvailabilityFilter } from "./AssignMDDrawer";
import { Spinner } from "components/Spinner";
import { NoResults } from "components/NoResults";
import { JsonDebugger } from "components/JsonDebugger";
import { distanceInWords, hhMmA, mmDdYyyy } from "utils/dateFormatters";
import { Button } from "components/Button";
import { FAIcon } from "components/FAIcon";
import { removeVoidKeys } from "utils/removeVoidKeys";
import { FilterPanel, ProviderAvailabilityFilter } from "./FilterPanel";
import { maybePluralize } from "utils/maybePluralize";

const PROVIDER_AVAILABILITY = gql`
  query ProviderAvailabilities($filter: ProviderAvailabilityFilterInput!) {
    providerAvailability(filter: $filter) {
      items {
        soonestAvailability
        provider {
          id
          firstName
          lastName
          primarySpecialty
          email
          assignedAppealRequestCount
        }
      }
    }
  }
`;

interface Data {
  providerAvailability: {
    items: {
      soonestAvailability: string;
      provider: {
        id: string;
        firstName: string;
        lastName: string;
        primarySpecialty: string;
        email: string;
        assignedAppealRequestCount: number;
      }
    }[];
  }
}

/**
 * AvailableProvidersSearch.
 */

interface AvailableProvidersSearchProps {
  modalityId: string;
  assignedProviderId?: string;
  caseFilterData: CaseProviderAvailabilityFilter;
  onProviderClick(providerId: string): Promise<any>;
};

export const AvailableProvidersSearch: FC<AvailableProvidersSearchProps> = props => {
  const { modalityId, assignedProviderId, caseFilterData, onProviderClick } = props;

  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const defaultFilter = toFilter(caseFilterData);
  const [filter, setFilter] = useState<ProviderAvailabilityFilter>(defaultFilter)

  function isLoading(id: string) {
    return loadingIds.includes(id);
  }

  const [data, setData] = useState<Data | null>(null);
  const { loading, error } = useQuery<Data>(PROVIDER_AVAILABILITY, { variables: { filter: removeVoidKeys(filter) }, onCompleted: setData });

  const assignProvider = useCallback(async (providerId: string) => {
    function setLoading(id: string) {
      setLoadingIds(ids => [id, ...ids.filter(i => i !== id)]);
    }

    function unsetLoading(id: string) {
      setLoadingIds(ids => ids.filter(i => i !== id));
    }

    setLoading(providerId);
    await onProviderClick(providerId);
    unsetLoading(providerId);
  }, [onProviderClick]);

  return (
    <div className="_AvailableProvidersSearch">
      <FilterPanel
        skillSearchModes={caseFilterData.skillSearchModes}
        modalityId={modalityId}
        defaultValue={defaultFilter}
        value={filter}
        onChange={setFilter}
      />

      {filter.excludeProviderDomainIds && filter.excludeProviderDomainIds.length > 0 ? (
        <div className="flex justify-center">
          <div className="bg-yellow-50 px-2 py-1 text-sm text-center text-yellow-700 border border-yellow-300 rounded">
            <p className="flex items-center">
              <FAIcon icon="exclamation-circle" className="mr-2" />
              Excluding {filter.excludeProviderDomainIds.length} {maybePluralize("provider", filter.excludeProviderDomainIds.length)}: {filter.excludeProviderDomainIds.join(", ")}
            </p>
          </div>
        </div>
      ) : null}

      {loading ? (
        <div className="p-6 text-center">
          <Spinner />
        </div>
      ) : error || !data?.providerAvailability ? (
        <p>Failed to load.</p>
      ) : data.providerAvailability.items.length === 0 ? (
        <NoResults icon="calendar-alt" text="No matching provider availabilities" />
      ) : (
        <>
          {data.providerAvailability.items.map(pa => (
            <div key={pa.provider.id} className="flex px-4 py-3 mt-3 bg-white border rounded shadow">
              <div className="flex-grow">
                <p>Available in <span className="font-bold">{distanceInWords(pa.soonestAvailability)}</span> <span className="text-gray-500">({mmDdYyyy(pa.soonestAvailability)} at {hhMmA(pa.soonestAvailability)})</span></p>
                <p>Current Case Load: <span className="font-bold">{pa.provider.assignedAppealRequestCount}</span></p>
                <div className="flex items-center">
                  <div className="text-purple-700 rounded-xl flex items-center justify-center w-8 h-8 p-0.5 text-sm bg-purple-200 shadow-inner">
                    <FAIcon icon="user-md" />
                  </div>
                  <div className="flex-grow pt-1 pl-3">
                    <p className="text-xl leading-relaxed">{pa.provider.firstName} {pa.provider.lastName}</p>
                    <p className="text-xs leading-tight text-gray-600">{pa.provider.primarySpecialty ? pa.provider.primarySpecialty + " - " : null}{pa.provider.email}</p>
                  </div>
                </div>
              </div>

              <div>
                {assignedProviderId === pa.provider.id ? (
                  <p>Currently Assigned</p>
                ) : (
                  <Button
                    type="button"
                    kind="secondary"
                    color="blue"
                    onClick={() => assignProvider(pa.provider.id)}
                    isLoading={isLoading(pa.provider.id)}
                    disabled={isLoading(pa.provider.id)}
                  >
                    Assign Case
                  </Button>
                )}
              </div>
            </div>
          ))}
        </>
      )}

      <JsonDebugger data={data} />
    </div>
  );
};

function toFilter(caseData: CaseProviderAvailabilityFilter): ProviderAvailabilityFilter {
  return {
    providerDomainIds: caseData.providerDomainIds,
    excludeProviderDomainIds: caseData.excludeProviderDomainIds,
    icd10: caseData.skillSearchModes.includes("icd10") ? caseData.icd10 : null,
    shiftBuffer: caseData.shiftBuffer,
    shiftStartBuffer: caseData.shiftStartBuffer,
    shiftEndBuffer: caseData.shiftEndBuffer,
    skillIds: caseData.skillSearchModes.includes("skills") ? (caseData.skills || []).map(s => s.id) : [],
    licensedInStateId: caseData.licensedInState?.id,
    externalSystemName: caseData.externalSystemName,
    searchTerm: ""
  }
}
